<template>
  <b-card
    class="mb-1 p-0"
  >
    <b-card-body class="p-1 pl-2">
      <div
        v-b-toggle="`collapse-standard-${domain.id}-${index}`"
        class="lead collapse-title"
      >
        <div class="d-flex align-items-center">
          <div class="p-0 d-flex">
            <feather-icon
              icon="AlignJustifyIcon"
              size="16"
              class="text-body align-middle m-0 p-0 domain-handle mr-2"
            />
          </div>
          <span class="mr-auto">
            {{ domain.name }}
          </span>
          <span v-if="domain.code">
            <b-badge
              pill
              variant="light-info"
              class="ml-2"
            >{{ domain.code }}</b-badge>
          </span>

          <div class="p-0">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="p-0"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle m-0 p-0"
                />
              </template>
              <b-dropdown-item @click.stop="$emit('addStandard',domain.id)">
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span>{{ $t('Add-New') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click.stop="$emit('editDomain',index)">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>{{ $t('Edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click.stop="$emit('deleteDomain',domain.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>{{ $t('delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </b-card-body>

    <!-- collapsable subject -->
    <b-collapse
      :id="`collapse-standard-${domain.id}-${index}`"
      :ref="`collapse-standard-${domain.id}-${index}`"
      class="mt-0 mb-1 px-1"
      :visible="index==0"
    >
      <b-list-group v-if="domain.standard && domain.standard.length>0">
        <draggable
          v-model="domain.standard"
          :options="{animation:300}"
          handle=".subject-handle"
          @change="updateOrder('standard')"
        >
          <b-list-group-item
            v-for="(standard, sIndex) in domain.standard"
            :key="sIndex"
            class="pr-0"
          >
            <div class="d-flex align-items-center">
              <div class="p-0 d-flex">
                <feather-icon
                  icon="AlignJustifyIcon"
                  size="16"
                  class="text-body align-middle m-0 p-0 subject-handle mr-2"
                />
              </div>
              <span class="mr-auto">
                {{ standard.name }}
              </span>
              <standard-detail
                :standard="standard"
                @editStandard="$emit('editStandard',sIndex,domain.id)"
                @deleteStandard="$emit('deleteStandard',standard.id)"
                @copyStandard="$emit('copyStandard',domain.id, standard.id,)"
              />
            </div>
          </b-list-group-item>
        </draggable>
      </b-list-group>

      <b-alert
        variant="warning"
        :show="domain.standard && domain.standard.length==0"
      >
        <div class="alert-body">
          <span style="font-size:12px">{{ $t('standard-module.no-standards') }}</span>
        </div>
      </b-alert>

    </b-collapse>
    <!-- collapsable subject -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody, BDropdown, BDropdownItem, VBToggle, BBadge, BCollapse, BAlert, BListGroupItem, BListGroup,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import draggable from 'vuedraggable'
import StandardDetail from '../standard/Show.vue'

export default {
  components: {
    BCollapse,
    BCard,
    BCardBody,
    BListGroupItem,
    BListGroup,
    BDropdown,
    StandardDetail,
    BDropdownItem,
    BAlert,
    BBadge,
    draggable,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    domain: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    updateOrder(type) {
      const input = this.domain.standard.map((e, i) => ({ id: e.id, order_index: i + 1 }))
      useJwt.updateOrder({
        model: type,
        input,
      })
    },
  },
}
</script>

<style scoped>
.active {
  background-color: rgba(22,29,49,0.4);
}
*{
  user-select: none;
}
.card-body{
  padding: 0;
}
</style>
