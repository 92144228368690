<template>
  <b-row class="standard-module">
    <grade-list
      @subjectSelected="subjectChanged"
      @updateGrades="list => grades = list"
    />
    <domain-list
      :subject-id="subjectId"
      :grade-id="gradeId"
      :grades="grades"
    />
  </b-row>
</template>
<script>
import {
  BRow,
} from 'bootstrap-vue'
import GradeList from './components/grade/Index.vue'
import DomainList from './components/domain/Index.vue'

export default {
  components: {
    BRow, GradeList, DomainList,
  },
  data() {
    return {
      subjectId: null,
      gradeId: null,
      grades: [],
    }
  },
  methods: {
    subjectChanged(id, gId) {
      this.subjectId = id
      this.gradeId = gId
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-good-table.scss";

.active {
  background-color: rgba(22,29,49,0.4);
}

.btn-link {
  padding: 0px;
  margin: 0px;
}
</style>

<style>
  .standard-module .dropdown-menu{
    transform: translate3d(-100px, 40px, 0px) !important;
  }

</style>
