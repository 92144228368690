<template>

  <!-- collapse -->
  <b-col
    md="6"
    xs="12"
  >
    <!-- create/edit grade modal -->
    <create-edit-grade
      :show="showGradeModal"
      :grade="gradeBeingEdited"
      @close="showGradeModal=false"
      @gradeCreated="gradeCreated"
      @gradeUpdated="gradeUpdated"
    />
    <!-- create/edit grade modal -->

    <!-- create/edit subject modal -->
    <create-edit-subject
      :show="showSubjectModal"
      :subject="subjectBeingEdited"
      :grade-id="activeGradeId"
      @close="showSubjectModal=false"
      @subjectCreated="subjectCreated"
      @subjectUpdated="subjectUpdated"
    />
    <!-- create/edit subject modal -->

    <!-- create grade button -->
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      class="mb-1 btn-block"
      @click="showGradeModal=true"
    >
      {{ $t("standard-module.create-grade") }}
    </b-button>
    <!-- create grade button -->

    <!-- list of grades -->
    <draggable
      v-if="grades.length"
      v-model="grades"
      :options="{animation:300}"
      handle=".grade-handle"
      class="grade-list"
      @change="updateOrder('grade')"
    >
      <grade-item
        v-for="(grade, index) in grades"
        :key="index"
        :index="index"
        :grade="grade"
        :active-subject="activeSubjectId"
        @deleteGrade="(id)=>idOfGradeBeingDeleted = id"
        @editGrade="editGrade"
        @addSubject="addSubject"
        @subjectSelected="subjectSelected"
        @gradeSelected="(id)=>activeGradeId=id"
        @editSubject="editSubject"
        @deleteSubject="(id)=>idOfSubjectBeingDeleted = id"
      />
    </draggable>
    <b-skeleton-wrapper v-else-if="!grades.length && isProcessing">
      <b-card
        v-for="i in 5"
        :key="i"
      >
        <b-skeleton
          width="85%"
          height="50px"
        />
      </b-card>
    </b-skeleton-wrapper>

    <!-- list of grades -->

    <!-- delete grade modal -->
    <delete-modal
      name="modal-delete-grade"
      :on-delete="deleteGrade"
      :title="$t('standard-module.delete-grade-title')"
      :sub-title="$t('standard-module.delete-grade-question')"
      :is-processing="isProcessing"
      :show="idOfGradeBeingDeleted!=null"
      @close="idOfGradeBeingDeleted=null"
    />
    <!-- delete grade modal -->
    <!-- delete subject modal -->
    <delete-modal
      name="modal-delete-subject"
      :on-delete="deleteSubject"
      :title="$t('standard-module.delete-subject-title')"
      :sub-title="$t('standard-module.delete-subject-question')"
      :is-processing="isProcessing"
      :show="idOfSubjectBeingDeleted!=null"
      @close="idOfSubjectBeingDeleted=null"
    />
    <!-- delete subject modal -->
  </b-col>

</template>
<script>
import {
  VBToggle,
  BCol, BButton, BSkeletonWrapper, BSkeleton, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import draggable from 'vuedraggable'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import CreateEditGrade from './CreateEdit.vue'
import CreateEditSubject from '../subject/CreateEdit.vue'
import GradeItem from './Show.vue'

export default {
  components: {
    CreateEditGrade,
    CreateEditSubject,
    GradeItem,
    BCol,
    BButton,
    DeleteModal,
    draggable,
    BSkeletonWrapper,
    BSkeleton,
    BCard,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      showGradeModal: false,
      showSubjectModal: false,
      indexOfGradeBeingEdited: null,
      idOfGradeBeingDeleted: null,
      isProcessing: false,
      indexOfSubjectBeingEdited: null,
      idOfSubjectBeingDeleted: null,
      activeGradeId: null,
      activeSubjectId: null,
      grades: [],
    }
  },

  computed: {
    gradeBeingEdited() {
      return this.indexOfGradeBeingEdited != null ? this.grades[this.indexOfGradeBeingEdited] : null
    },
    subjectBeingEdited() {
      return this.indexOfSubjectBeingEdited != null ? this.grades.find(e => e.id === this.activeGradeId).subjects[this.indexOfSubjectBeingEdited] : null
    },
  },
  mounted() {
    this.getGrades()
  },
  methods: {
    getGrades() {
      this.isProcessing = true
      useJwt.getStandardGrades({ per_page: 999, library: this.$route.query.library }).then(response => {
        this.grades = response.data.data.data
        this.$emit('updateGrades', response.data.data.data)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    gradeCreated() {
      this.showGradeModal = false
      this.getGrades()
    },
    gradeUpdated() {
      this.showGradeModal = false
      this.indexOfGradeBeingEdited = null
      this.getGrades()
    },
    subjectCreated() {
      this.indexOfSubjectBeingEdited = null
      this.showSubjectModal = false
      this.getGrades()
    },
    subjectUpdated() {
      this.indexOfSubjectBeingEdited = null
      this.showSubjectModal = false
      this.getGrades()
    },
    deleteGrade() {
      this.isProcessing = true
      useJwt.deleteStandardGrade(this.idOfGradeBeingDeleted).then(res => {
        this.showSuccessMessage(res)
        this.idOfGradeBeingDeleted = null
        this.getGrades()
      }).finally(() => {
        this.isProcessing = false
      })
    },
    deleteSubject() {
      this.isProcessing = true
      useJwt.deleteStandardSubject(this.idOfSubjectBeingDeleted).then(res => {
        this.showSuccessMessage(res)
        this.idOfSubjectBeingDeleted = null
        this.getGrades()
      }).finally(() => {
        this.isProcessing = false
      })
    },
    subjectSelected(id, gradeId) {
      this.activeSubjectId = id
      this.$emit('subjectSelected', id, gradeId)
    },
    addSubject(gradeId) {
      this.activeGradeId = gradeId
      this.indexOfSubjectBeingEdited = null
      this.showSubjectModal = true
    },
    editSubject(index, gId) {
      this.activeGradeId = gId
      this.indexOfSubjectBeingEdited = index
      this.showSubjectModal = true
    },
    editGrade(index) {
      this.showGradeModal = true
      this.indexOfGradeBeingEdited = index
    },
    updateOrder(type) {
      const input = this.grades.map((e, i) => ({ id: e.id, order_index: i + 1 }))
      useJwt.updateOrder({
        model: type,
        input,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.active {
    background-color: rgba(22,29,49,0.4);
}

.btn-link {
    padding: 0px;
    margin: 0px;
}
.grade-list {
  overflow-y: scroll;
  max-height: 80vh;
}
</style>
